import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {mockApplicationData, stepData} from "../../mock/data.js"
import {http_auth} from "../../utility/Utils"
import {toast} from "react-toastify"

export const getApplications = createAsyncThunk('application/getApplications', async (filter, {rejectWithValue}) => {
    try {
        const response = await http_auth.get(`/books`, {
            params: filter
        })
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})

export const getApplicationById = createAsyncThunk('application/getApplicationById', async (id, {rejectWithValue}) => {
    try {
        const response = await http_auth.get(`/books/${id}`)
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})

export const removeApplicationById = createAsyncThunk('application/removeApplicationById', async (id, {rejectWithValue}) => {
    try {
        const response = await http_auth.delete(`/books/${id}`)
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})

export const changeApplicationStatus2 = createAsyncThunk('application/changeApplicationStatus2', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.post(`/books/changeStep`, data)
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})

export const changeProductItem = createAsyncThunk('application/changeProductItem', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.patch(`/books/products`, data)
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})

export const createInvoice = createAsyncThunk('application/createInvoice', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.post(`/invoices`, data)
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})

export const updateInvoice = createAsyncThunk('application/updateInvoice', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.patch(`/invoices/${data?.id}`, data?.body)
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})

export const removeInvoiceById = createAsyncThunk('application/removeInvoiceById', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.delete(`/invoices/${data?.id}`)
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})


export const getInvoiceResultData = createAsyncThunk('application/getInvoiceResultData', async (invoiceId, {rejectWithValue}) => {
    try {
        const response = await http_auth.get(`/invoices/result/${invoiceId}`)
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})

export const getInvoiceList = createAsyncThunk('application/getInvoiceList', async (_, {rejectWithValue}) => {
    try {
        const response = await http_auth.get(`/invoices`)
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})


export const changeInvoiceStep = createAsyncThunk('application/changeInvoiceStep', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.post(`/invoices/changeStep`, data)
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})

export const getDataInvoice = createAsyncThunk('application/getDataInvoice', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.post(`/invoices/dataInvoice`, data)
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})

export const getDataManifest = createAsyncThunk('application/getDataManifest', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.post(`/invoices/dataManifest`, data)
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})


const initialState = {
    loading: false,
    books: [],
    products: [],
    manifest: [],
    book: null,
    invoiceNumber: null,
    invoiceDate: null,
    totalSum: null,
    totalWeight: null,
    invoiceId: null,

    invoiceData: null,
    stepData,
    page: 0,
    limit: 0,
    pageCount: 0,
    totalCount: 0,
    applications: mockApplicationData,
    invoices: []
}

const reducers = {
    removeApplication: (state, action) => {
        state.books = state.books.filter(item => item.id !== action.payload)
    },
    removeInvoice: (state, action) => {
        state.invoice = state.invoice.filter(item => item.id !== action.payload)
    },
    setProductItemDesc: (state, action) => {
        state.products[action.payload?.index].desc = action.payload?.value
    },
    setProductItemWeight: (state, action) => {
        state.products[action.payload?.index].weight = action.payload?.value
    }
}

export const applicationSlice = createSlice({
    name: 'application',
    initialState,
    reducers,
    extraReducers: {
        [getApplications.fulfilled]: (state, action) => {
            const data = action.payload?.data

            state.books = data?.books
            state.page = data?.page
            state.limit = data?.limit
            state.pageCount = data?.page_count
            state.totalCount = data?.total_count
            state.isLoading = false
        },
        [getApplications.pending]: (state) => {
            state.isLoading = true
        },
        [getApplications.rejected]: (state) => {
            state.isLoading = false
        },

        [getApplicationById.fulfilled]: (state, action) => {
            const data = action.payload.data
            const arrData = []

            for (const product of data?.products) {
                const data = {
                    id: product?.id,
                    name: product?.name,
                    cost_usd: product?.cost_usd,
                    quantity: product?.quantity,
                    weight: product?.weight,
                    desc: product?.description
                }
                arrData.push(data)
            }

            state.book = data
            state.products = arrData
            state.isLoading = false
        },
        [getApplicationById.pending]: (state) => {
            state.isLoading = true
        },
        [getApplicationById.rejected]: (state) => {
            state.isLoading = false
        },

        [changeApplicationStatus2.fulfilled]: (state, action) => {
            state.books = state.books.filter(item => item.id !== action.payload.data?.id)
            toast.success('Статус изменен')
            state.isLoading = false
        },
        [changeApplicationStatus2.pending]: (state) => {
            state.isLoading = true
        },
        [changeApplicationStatus2.rejected]: (state) => {
            state.isLoading = false
        },

        [changeInvoiceStep.fulfilled]: (state, action) => {
            state.books = state.books.filter(item => item.id !== action.payload.data?.id)
            toast.success('Вы приняли посылку!')
            state.isLoading = false
        },
        [changeInvoiceStep.pending]: (state) => {
            state.isLoading = true
        },
        [changeInvoiceStep.rejected]: (state) => {
            state.isLoading = false
        },

        [getInvoiceResultData.fulfilled]: (state, action) => {
            state.invoiceData = action.payload.data
            state.isLoading = false
        },
        [getInvoiceResultData.pending]: (state) => {
            state.isLoading = true
        },
        [getInvoiceResultData.rejected]: (state) => {
            state.isLoading = false
        },

        [getInvoiceList.fulfilled]: (state, action) => {
            const data = action.payload.data

            state.invoices = data?.invoices
            state.page = data?.page
            state.limit = data?.limit
            state.pageCount = data?.page_count
            state.totalCount = data?.total_count
            state.isLoading = false
        },
        [getInvoiceList.pending]: (state) => {
            state.isLoading = true
        },
        [getInvoiceList.rejected]: (state) => {
            state.isLoading = false
        },

        [getDataInvoice.fulfilled]: (state, action) => {
            const data = action.payload.data
            state.invoiceNumber = data?.invoice_number
            state.invoiceDate = data?.invoice_date
            state.totalSum = data?.total_sum_invoice
            state.products = data?.products
            state.isLoading = false
        },
        [getDataInvoice.pending]: (state) => {
            state.isLoading = true
        },
        [getDataInvoice.rejected]: (state) => {
            state.isLoading = false
        },

        [createInvoice.fulfilled]: (state, action) => {
            state.invoiceId = action.payload.invoice_id
            state.isLoading = false
        },
        [createInvoice.pending]: (state) => {
            state.isLoading = true
        },
        [createInvoice.rejected]: (state) => {
            state.isLoading = false
        },

        [getDataManifest.fulfilled]: (state, action) => {
            state.totalSum = action.payload?.data?.total_sum_manifest
            state.totalWeight = action.payload?.data?.total_weight
            state.invoiceData = action.payload?.data?.invoice_data
            state.manifest = action.payload?.data?.data
            state.isLoading = false
        },
        [getDataManifest.pending]: (state) => {
            state.isLoading = true
        },
        [getDataManifest.rejected]: (state) => {
            state.isLoading = false
        },

        [removeApplicationById.fulfilled]: (state, action) => {
            state.books = state.books.filter(item => item.id !== action.meta.arg)
            toast.success("Заказ был удален!!!")
            state.isLoading = false
        },
        [removeApplicationById.pending]: (state) => {
            state.isLoading = true
        },
        [removeApplicationById.rejected]: (state) => {
            state.isLoading = false
        }
    }
})

export const {
    removeApplication,
    removeInvoice,
    setProductItemDesc,
    setProductItemWeight
} = applicationSlice.actions
export default applicationSlice.reducer
