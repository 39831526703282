export const mockApplicationData = [
    {
        id: "1",
        number: "№4875520021",
        date: "10.05.2023",
        countProduct: 8,
        firstName: "Стефания",
        lastName: "Колосова",
        middleName: "Максимовна",
        weight: "3,5 кг",
        phone: "+998 (90) 142 21 51",
        status: "approved",
        price: "150 000 сум"
    },
    {
        id: "2",
        number: "№5878900023",
        date: "10.05.2023",
        countProduct: 3,
        firstName: "Зилова",
        lastName: "Еиловна",
        middleName: "Данииловна",
        weight: "3,5 кг",
        phone: "+998 (90) 142 21 51",
        status: "paid",
        price: "150 000 сум"
    },
    {
        id: "5",
        number: "№5878900023",
        date: "10.05.2023",
        countProduct: 3,
        firstName: "Зилова",
        lastName: "Еиловна",
        middleName: "Данииловна",
        weight: "3,5 кг",
        phone: "+998 (90) 142 21 51",
        status: "over",
        price: "150 000 сум"
    },
    {
        id: "3",
        number: "№7489002500",
        date: "10.05.2023",
        countProduct: 25,
        firstName: "Панилови",
        lastName: "Ениловa",
        middleName: "Данилович",
        weight: "3,5 кг",
        phone: "+998 (90) 142 21 51",
        status: "delivered",
        price: "150 000 сум"
    },
    {
        id: "4",
        number: "№7458900063",
        date: "10.05.2023",
        countProduct: 14,
        firstName: "Литри",
        lastName: "Дмитриев",
        middleName: "Дмитриевич",
        weight: "3,5 кг",
        phone: "+998 (90) 142 21 51",
        status: "new",
        price: "150 000 сум"
    },
    {
        id: "6",
        number: "№7458900063",
        date: "10.05.2023",
        countProduct: 14,
        firstName: "Литри",
        lastName: "Дмитриев",
        middleName: "Дмитриевич",
        weight: "3,5 кг",
        phone: "+998 (90) 142 21 51",
        status: "new",
        price: "150 000 сум"
    }
]

export const stepData = [
    {
        step: 1,
        status: "Noviy",
        act: "Client yangi zayavka tashadi",
        actor: "client-app"
    },
    {
        step: 2,
        status: "Ojidayte na oplatu||Odobrenno",
        act: "Admin zayavkani odobrit qiladi",
        actor: 'admin_app'
    },
    {
        step: 3,
        status: "Oplacheno",
        act: "Client pulni to'ladi",
        actor: 'client-app'
    },
    {
        step: 4,
        status: "na sklade",
        act: "Admin zayavkaga invoice yaratdi",
        actor: "admin_bin"
    },
    {
        step: 5,
        status: "admin ptt",
        act: "Admin ptt barcodeni scaner qildi va yukni oldis",
        actor: "admin_ptt"
    },

    {
        step: 6,
        status: "in aeroport turkiya",
        act: "Admin ptt scaner qiladi  yukni aeroportdan uchirib yubordi",
        actor: "admin_cargo"
    },
    {
        step: 7,
        status: "na sklade uzbekistan",
        act: "Admin cpt scaner qildi yukni aeroportdan oldi",
        actor: "admin_cpt"
    },
    {
        step: 8,
        status: "na dostavke",
        act: "Admin bts scaner qildi va yukni yetkzaish jarayonida",
        actor: "admin_bts"
    },
    {
        step: 9,
        status: "Yuk yetkazildi",
        act: "Admin bts qiladi",
        actor: "admin_bts"
    },
    {
        step: 1000,
        status: "otmeneno",
        act: "Zayavka otkaz bo'ldi",
        actor: 'admin_app'
    }
]
export const mockCommentData = [
    {
        id: 1,
        user: "Агеева Мария Никитична",
        createdAt: '2023-05-26T16:55:39.401+05:00',
        star: 3,
        comment: "Praesent sed quam et ex dapibus venenatis"
    },
    {
        id: 2,
        user: "Фомин Матвей Тимофеевич",
        createdAt: '2023-05-26T16:55:39.401+05:00',
        star: 4,
        comment: "Pellentesque eu magna metus"
    },
    {
        id: 3,
        user: "Шишкина Ксения Матвеевна",
        createdAt: '2023-05-26T16:55:39.401+05:00',
        star: 5,
        comment: "Integer auctor purus sit amet viverra tincidunt"
    },
    {
        id: 4,
        user: "Короткова Марина Артёмовна",
        createdAt: '2023-05-26T16:55:39.401+05:00',
        star: 3,
        comment: "Fusce at tortor mollis, laoreet velit vitae, vehicula erat . . ."
    },
    {
        id: 5,
        user: "Фомин Матвей Тимофеевич",
        createdAt: '2023-05-26T16:55:39.401+05:00',
        star: 4,
        comment: "Praesent sed quam et ex dapibus venenatis"
    },
    {
        id: 6,
        user: "Шишкина Ксения Матвеевна",
        createdAt: '2023-05-26T16:55:39.401+05:00',
        star: 5,
        comment: "Integer auctor purus sit amet viverra tincidunt"
    }

]

export const mockTranslationData = [
    {
        id: 1,
        title: {
            uz: "Bo'sh ish o'rni",
            ru: "Вакансия",
            en: "Vacancy"
        },
        tag: "vacancy_count_vacancy",
        createdAt: '2023-05-26T16:55:39.401+05:00',
        status: "ACTIVE",
        type: "FRONT"
    },
    {
        id: 2,
        title: {
            uz: "Bo'sh ish o'rni",
            ru: "Вакансия",
            en: "Vacancy"
        },
        tag: "vacancy_count_vacancy",
        createdAt: '2023-05-26T16:55:39.401+05:00',
        status: "ACTIVE",
        type: "FRONT"
    },
    {
        id: 3,
        title: {
            uz: "Bo'sh ish o'rni",
            ru: "Вакансия",
            en: "Vacancy"
        },
        tag: "vacancy_count_vacancy",
        createdAt: '2023-05-26T16:55:39.401+05:00',
        status: "ACTIVE",
        type: "FRONT"
    },
    {
        id: 4,
        title: {
            uz: "Bo'sh ish o'rni",
            ru: "Вакансия",
            en: "Vacancy"
        },
        tag: "vacancy_count_vacancy",
        createdAt: '2023-05-26T16:55:39.401+05:00',
        status: "ACTIVE",
        type: "FRONT"
    }
]

export const mockInvoiceData = [
    {
        id: 1,
        number: "№58749600",
        createdAt: '2023-05-26T16:55:39.401+05:00',
        price: "65.4$",
        weight: "15 кг",
        address: "Tashkent, 3, Rzhevsky Str., 700015"
    },
    {
        id: 2,
        number: "№589611500",
        createdAt: '2023-05-26T16:55:39.401+05:00',
        price: "41$",
        weight: "10 кг",
        address: "Tashkent, 19, Navoi Str., 700001"
    },
    {
        id: 3,
        number: "№36696200",
        createdAt: '2023-05-26T16:55:39.401+05:00',
        price: "96$",
        weight: "8 кг",
        address: "Tashkent Region, Street Bogishamol, Yunusabad Area, 100084"
    },
    {
        id: 4,
        number: "№77489600",
        createdAt: '2023-05-26T16:55:39.401+05:00',
        price: "547$",
        weight: "5 кг",
        address: "Markhamat, street B.SULAYMONOV, 40"
    }
]