import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {mockCommentData} from "../../mock/data.js"
import {getAuthorizationHeader, http_auth} from "../../utility/Utils"

export const uploadFile = createAsyncThunk('contents/uploadFile', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.post(`/upload`, data)
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})

export const createNewsPaper = createAsyncThunk('contents/createNewsPaper', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.post(`/news`, data)
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})

export const getNewsPaperList = createAsyncThunk('contents/getNewsPaperList', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.get(`/news`, {
            params: data
        })
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})

export const deleteNewsPaper = createAsyncThunk('contents/deleteNewsPaper', async (id, {rejectWithValue}) => {
    try {
        const response = await http_auth.delete(`/news/${id}`)
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})


export const getTranslateList = createAsyncThunk('contents/getTranslateList', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.get(`/langs`, {
            params: data
        })
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})

export const deleteTranslate = createAsyncThunk('contents/deleteTranslate', async (id, {rejectWithValue}) => {
    try {
        const response = await http_auth.delete(`/langs/${id}`)
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})

export const createTranslate = createAsyncThunk('contents/createTranslate', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.post(`/langs`, data)
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})

export const getRegions = createAsyncThunk('contents/getRegions', async (_, {rejectWithValue}) => {
    try {
        const response = await http_auth.get(`/regions`)
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})

export const getDistrictByRegionId = createAsyncThunk('contents/getDistrictByRegionId', async (
    regionId,
    {rejectWithValue}) => {
    try {
        const response = await http_auth.get(`/regions/districts/${regionId}`)
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})

export const getQuarterByDistrictId = createAsyncThunk('contents/getQuarterByDistrictId', async (districtId, {rejectWithValue}) => {
    try {
        const response = await http_auth.get(`/regions/quarters/${districtId}`)
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})

export const createZipcodes = createAsyncThunk('contents/createZipcodes', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.post(`/zipcode`, data, {
            headers: {Authorization: getAuthorizationHeader()}
        })
        if (response.data === null) return rejectWithValue(response?.data)
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})

const initialState = {
    loading: false,
    fileUrl: null,
    news: [],
    translations: [],
    regions: [],
    districts: [],
    quarters: [],
    comments: mockCommentData,
    itemStep: 0,
    page: 0,
    limit: 0,
    pageCount: 0,
    totalCount: 0
}

const reducers = {
    removeItem: (state, action) => {
        state.news = state.news.filter(item => item.id !== action.payload.id)
    }
}

export const contentsSlice = createSlice({
    name: 'contents',
    initialState,
    reducers,
    extraReducers: {
        [uploadFile.fulfilled]: (state, action) => {
            state.fileUrl = action.payload.url
            state.isLoading = false
        },
        [uploadFile.pending]: (state) => {
            state.isLoading = true
        },
        [uploadFile.rejected]: (state) => {
            state.isLoading = false
        },
        // ================= =================== =================== ================
        [createNewsPaper.fulfilled]: (state, action) => {
            state.news.push(action.payload.data)
            state.isLoading = false
        },
        [createNewsPaper.pending]: (state) => {
            state.isLoading = true
        },
        [createNewsPaper.rejected]: (state) => {
            state.isLoading = false
        },
        [getNewsPaperList.fulfilled]: (state, action) => {
            const data = action.payload.data
            state.news = data.news
            state.page = data?.page
            state.limit = data?.limit
            state.pageCount = data?.page_count
            state.totalCount = data?.total_count
            state.isLoading = false
        },
        [getNewsPaperList.pending]: (state) => {
            state.isLoading = true
        },
        [getNewsPaperList.rejected]: (state) => {
            state.isLoading = false
        },
        [deleteNewsPaper.fulfilled]: (state, action) => {
            state.news = state.news.filter(item => item?.id !== action.meta.arg)
            state.isLoading = false
        },
        [deleteNewsPaper.pending]: (state) => {
            state.isLoading = true
        },
        [deleteNewsPaper.rejected]: (state) => {
            state.isLoading = false
        },

        // =============== =================== ==================== =======
        [getTranslateList.fulfilled]: (state, action) => {
            const data = action.payload.data
            state.translations = data.langs
            state.page = data?.page
            state.limit = data?.limit
            state.pageCount = data?.page_count
            state.totalCount = data?.total_count
            state.isLoading = false
        },
        [getTranslateList.pending]: (state) => {
            state.isLoading = true
        },
        [getTranslateList.rejected]: (state) => {
            state.isLoading = false
        },
        [deleteTranslate.fulfilled]: (state, action) => {
            state.translations = state.translations.filter(item => item?.id !== action.meta.arg)
            state.isLoading = false
        },
        [deleteTranslate.pending]: (state) => {
            state.isLoading = true
        },
        [deleteTranslate.rejected]: (state) => {
            state.isLoading = false
        },
        [createTranslate.fulfilled]: (state, action) => {
            state.translations.push(action.payload.data)
            state.isLoading = false
        },
        [createTranslate.pending]: (state) => {
            state.isLoading = true
        },
        [createTranslate.rejected]: (state) => {
            state.isLoading = false
        },
        [getRegions.fulfilled]: (state, action) => {
            state.regions = action.payload.data
            state.isLoading = false
        },
        [getRegions.pending]: (state) => {
            state.isLoading = true
        },
        [getRegions.rejected]: (state) => {
            state.isLoading = false
        },

        [getDistrictByRegionId.fulfilled]: (state, action) => {
            state.districts = action.payload.data
            state.isLoading = false
        },
        [getDistrictByRegionId.pending]: (state) => {
            state.isLoading = true
        },
        [getDistrictByRegionId.rejected]: (state) => {
            state.isLoading = false
        },

        [getQuarterByDistrictId.fulfilled]: (state, action) => {
            state.quarters = action.payload.data
            state.isLoading = false
        },
        [getQuarterByDistrictId.pending]: (state) => {
            state.isLoading = true
        },
        [getQuarterByDistrictId.rejected]: (state) => {
            state.isLoading = false
        }
    }
})

export const {removeItem} = contentsSlice.actions
export default contentsSlice.reducer