import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {getAuthorizationHeader, http, http_auth, ROLE, TOKEN} from "../../utility/Utils"
import {toast} from "react-toastify"
import {stepData} from "../../mock/data"

export const getUsersList = createAsyncThunk('users/getUsersList', async (filter, {
    rejectWithValue
}) => {
    try {
        const response = await http_auth.get(`/users`, {
            params: filter
        })
        if (response.data?.data === null) return rejectWithValue(response.data.message)
        return response.data
    } catch (error) {
        return rejectWithValue(error.message)
    }
})

export const getUserById = createAsyncThunk('users/getUserById', async (id, {
    rejectWithValue
}) => {
    try {
        const response = await http_auth.get(`/users/${id}`)
        if (response.data?.data === null) return rejectWithValue(response.data.message)
        return response.data
    } catch (error) {
        return rejectWithValue(error.message)
    }
})


export const editUserById = createAsyncThunk('users/getUserById', async (data, {
    rejectWithValue
}) => {
    try {
        const response = await http_auth.patch(`/users/${data.id}`, data)
        if (response.data?.data === null) return rejectWithValue(response.data.message)
        return response.data
    } catch (error) {
        return rejectWithValue(error.message)
    }
})

export const createUser = createAsyncThunk('users/createUser', async (data, {
    rejectWithValue
}) => {
    try {
        const response = await http_auth.post(`/users`, data)
        if (response.data?.data === null) return rejectWithValue(response.data.message)
        return response.data
    } catch (error) {
        return rejectWithValue(error.message)
    }
})

export const removeUserById = createAsyncThunk('users/removeUserById', async (id, {
    rejectWithValue
}) => {
    try {
        const response = await http_auth.delete(`/users/${id}`)
        if (response.data?.data === null) return rejectWithValue(response.data.message)
        return response.data
    } catch (error) {
        return rejectWithValue(error.message)
    }
})

export const login = createAsyncThunk('users/login', async (data, {rejectWithValue}) => {
    try {
        const response = await http.post('/auth/admin', data)
        if (response.data?.data === null) return rejectWithValue(response.data.message)
        return response.data
    } catch (err) {
        if (err.response.status === 401) toast.error("Ваш пароль или логин неверны! Пожалуйста, попробуйте еще раз!")
        return rejectWithValue(err.response.data)
    }
})

export const userMe = createAsyncThunk('users/userMe', async (_, {rejectWithValue}) => {
    try {
        const response = await http_auth.get('/users/me', {
            headers: {Authorization: getAuthorizationHeader()}
        })
        if (response.data?.data === null) return rejectWithValue(response.data.message)
        return response.data
    } catch (err) {
        if (err.response.status === 401) toast.error("Ваш пароль или логин неверны! Пожалуйста, попробуйте еще раз!")
        return rejectWithValue(err.response.data)
    }
})

const initialState = {
    loading: false,
    userData: null,
    isAuth: false,
    users: [],
    user: null,
    stepData,
    itemStep: 0,
    page: 0,
    limit: 0,
    pageCount: 0,
    totalCount: 0
}

const reducers = {
    removeUser: (state, action) => {
        state.users = state.users.filter(item => item.id !== action.payload)
    },
    logout: (state) => {
        state.isAuth = false
        state.userData = null
        localStorage.clear()
    },
    findStep: (state) => {
        state.itemStep = state.stepData.find(item => item.actor === state.userData.role).step
    }
}

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers,
    extraReducers: {
        [login.fulfilled]: (state, action) => {
            state.accessToken = action?.payload?.token
            state.userData = action.payload?.data
            localStorage.setItem(ROLE, action.payload.data?.role)
            localStorage.setItem(TOKEN, action.payload.token)
            state.isAuth = true
            state.isLoading = false
        },
        [login.pending]: (state) => {
            state.isLoading = true
        },
        [login.rejected]: (state, action) => {
            if (action.payload?.error?.statusCode === 404) {
                toast.error("Такого пользователя не существует. Пожалуйста, зарегистрируйтесь!")
            }
            state.isAuth = false
            state.isLoading = false
        },

        [userMe.fulfilled]: (state, action) => {
            state.userData = action.payload?.data
            state.isAuth = true
            state.isLoading = false
        },
        [userMe.rejected]: (state) => {
            state.isAuth = false
            state.isLoading = false
        },

        [getUsersList.fulfilled]: (state, action) => {
            const data = action.payload.data
            state.users = data.users
            state.page = data?.page
            state.limit = data?.limit
            state.pageCount = data?.page_count
            state.totalCount = data?.total_count
            state.isLoading = false
        },
        [getUsersList.pending]: (state) => {
            state.isLoading = true
        },
        [getUsersList.rejected]: (state) => {
            state.isLoading = false
        },

        [getUserById.fulfilled]: (state, action) => {
            state.user = action.payload.data
            state.isLoading = false
        },
        [getUserById.rejected]: (state) => {
            state.isLoading = false
        },

        [createUser.fulfilled]: (state, action) => {
            state.users.push(action.payload.data)
            state.isLoading = false
        },
        [createUser.pending]: (state) => {
            state.isLoading = true
        },
        [createUser.rejected]: (state, action) => {
            console.log(action)
            state.isLoading = false
        }
    }
})

export const {
    removeUser,
    logout,
    findStep
} = usersSlice.actions
export default usersSlice.reducer