import axios from 'axios'

export const BASE_URL = "http://127.0.0.1:8000/api/v1"
// export const BASE_URL = "http://api.guzarpost.uz:8000/api/v1/"
// export const baseUrl = "http://localhost:8000/api/v1"
export const baseUrl = "https://api.guzarpost.uz/api/v1/"
export const ROLE = "guzarpost.com.role"
export const TOKEN = "TOKEN"
export const INPUT_MSG = "Iltimos, to`liq kiriting"

export const getToken = () => localStorage.getItem(TOKEN)
export const getAuthorizationHeader = () => `Bearer ${getToken()}`
export const PATH_URL = (role) => `/${role === 'admin' || role === 'superadmin' ? 'administrator' : role === 'moderator' ? 'moderator' : role === 'admin_app' || role === 'admin_ptt' || role === 'admin_cpt' || role === 'admin_cargo' || role === 'admin_bin' ? 'operator' : 'not-accept'}`

export const http = axios.create({
    baseURL: baseUrl,
    headers: {
        AccessControlAllowOrigin: "*",
        ContentType: "application/json",
        Accept: "application/json"
    }
})

export const http_auth = axios.create({
    baseURL: baseUrl,
    headers: {
        AccessControlAllowOrigin: "*",
        ContentType: "application/json",
        Authorization: getAuthorizationHeader()
    }
})


export const selectThemeColors = theme => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: '#7367f01a', // for option hover bg-color
        primary: '#7367f0', // for selected option bg-color
        neutral10: '#7367f0', // for tags bg-color
        neutral20: '#ededed', // for input border-color
        neutral30: '#ededed' // for input hover border-color
    }
})
