// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import application from "./reducers/application"
import users from "./reducers/users"
import contents from "./reducers/contents"

const rootReducer = {
    navbar,
    layout,
    application,
    contents,
    users
}

export default rootReducer
